<template>
  <div class="container">
    <nav class="d-flex align-items-center mb-3 mb-lg-4" aria-label="breadcrumb">
      <router-link class="text-brown text-decoration-none mr-3" to="/product">
        <svg
          class="mr-3"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_15_368)">
            <path
              d="M31.866 29.046C31.916 28.71 32.975 20.636 28.227 15.08C25.31 11.666 20.809 9.79703 14.997 9.61203L14.966 3.99903C14.966 3.61303 14.743 3.26203 14.395 3.09603C14.046 2.93303 13.633 2.98003 13.334 3.22403L0.366998 13.866C0.134999 14.056 -1.52259e-06 14.34 -0.00100152 14.64C-0.00200152 14.94 0.131998 15.224 0.363998 15.414L13.332 26.157C13.63 26.404 14.047 26.455 14.393 26.289C14.744 26.124 14.966 25.771 14.966 25.385L14.997 19.646C29.006 19.636 30.002 28.612 30.036 28.983C30.079 29.487 30.398 29.88 30.904 29.896C30.916 29.897 30.927 29.897 30.938 29.897C31.433 29.897 31.792 29.536 31.866 29.046ZM13.261 17.922C13.073 18.11 12.967 18.364 12.967 18.63V23.268L2.578 14.646L12.967 6.10403V10.597C12.967 11.15 13.414 11.597 13.967 11.597C19.657 11.597 24.004 13.245 26.702 16.373C28.731 18.727 29.664 21.608 29.983 23.999C27.799 21.015 23.975 17.636 13.968 17.63C13.968 17.63 13.967 17.63 13.966 17.63C13.702 17.63 13.448 17.735 13.261 17.922Z"
              fill="#6B5045"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_368">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        返回
      </router-link>
      <ol class="breadcrumb bg-transparent p-0 mb-0">
        <li class="breadcrumb-item">
          <router-link to="/">主頁</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link to="/product">產品推廣</router-link>
        </li>
        <li class="breadcrumb-item">創建產品</li>
      </ol>
    </nav>

    <b-alert show variant="danger" v-if="veeErrors.all().length">
      <ul class="list-unstyled mb-0">
        <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">
          {{ error }}
        </li>
      </ul>
    </b-alert>

    <div class="card rounded-0 border-0 p-3 p-lg-4">
      <b-form @submit.stop.prevent="onSubmitCreateProduct">
        <b-row class="mb-3">
          <b-col sm="6" lg="4" class="order-2 order-lg-1">
            <div>
              <b-carousel
                class="preview-image d-flex align-items-center justify-content-center text-brown border shadow-sm mb-2 mr-lg-3"
                id="carousel-1"
                controls
                indicators
                :interval="4000"
                background="#ababab"
                img-width="218"
                img-height="160"
                @sliding-start="updateIndex"
              >
                <div
                  v-if="!previewImagesList.length"
                  class="d-flex align-items-center justify-content-center"
                >
                  預覽產品相片
                </div>
                <b-carousel-slide
                  v-for="(image, index) in previewImagesList"
                  :key="index"
                >
                  <template v-slot:img>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="height: 100%; width: 100%"
                    >
                      <img
                        :src="image"
                        class="img-fluid"
                        style="object-fit: cover; width: 100%; height: 100%"
                      />
                    </div>
                  </template>
                  <div
                    v-if="!!previewImagesList.length"
                    class="d-flex align-items-center justify-content-center pt-4"
                  >
                    {{ currentIndex + 1 }} /{{ previewImagesList.length }}
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </b-col>
          <b-col sm="6" lg="4" class="order-3 order-lg-2">
            <div class="d-flex">
              <b-form-file
                id="previewImage"
                class="d-none"
                ref="fileImage"
                name="image"
                accept=".jpg, .jpeg, .png,"
                v-validate="{
                  required: false,
                  ext: 'jpeg|jpg|png',
                  size: 1024,
                }"
                :state="validateState('image')"
                aria-describedby="input-image-live-feedback"
                data-vv-as="image"
                @input="browseImage"
                multiple
                :disabled="
                  previewImagesList.length >= 10 && !!previewImagesList
                "
              ></b-form-file>
              <div class="mb-3">
                <label
                  :class="{
                    disabledLabel:
                      previewImagesList.length >= 10 && !!previewImagesList,
                  }"
                  for="previewImage"
                  class="bg-white text-brown border border-brown--light rounded-6px py-2 px-4"
                  >上傳產品相片</label
                >
              </div>
              <div v-if="previewImagesList.length" class="ml-4">
                <b-button v-b-modal.modal-1>查看全部</b-button>
                <b-modal size="lg" id="modal-1" title="預覽產品相片">
                  <div class="d-flex flex-wrap">
                    <div
                      class="mx-2 my-4"
                      style="width: 175px; height: 160px"
                      v-for="(image, index) in previewImagesList"
                      :key="index"
                    >
                      <div
                        class="border overflow-hidden rounded"
                        style="width: 100%; height: 100%"
                      >
                        <img
                          class=""
                          :src="image"
                          alt="Preview"
                          style="width: 100%; height: 100%; object-fit: cover"
                        />
                      </div>
                      <img
                        @click="removeImage(index)"
                        style="margin-top: -60px; cursor: pointer"
                        src="../../assets/svg/delete.svg"
                        width="32"
                        height="32"
                      />
                    </div>
                  </div>
                  <template v-slot:modal-footer
                    ><b-button block @click="$bvModal.hide('modal-1')"
                      >關閉</b-button
                    ></template
                  >
                </b-modal>
              </div>
            </div>
            <div>
              <div class="mb-1">只接受 jpg,jpeg,png格式</div>
              <div class="mb-1">建議尺寸 1,200 x 630 px</div>
              <div class="mb-1">最大上傳檔案 1Mb</div>
            </div>
          </b-col>

          <b-col lg="2" class="order-1 order-lg-3 mb-2">
            <div class="float-lg-right">
              <div class="d-flex align-items-center">
                <div class="mr-2">啟用</div>
                <b-form-checkbox
                  name="check-button"
                  class="custom-switch--success"
                  switch
                  v-model="formControl.active"
                  :checked="formControl.active"
                ></b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="posId" class="text-brown"
              >產品編號 <span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="posId"
              class="rounded-0"
              name="posId"
              v-model="formControl.posId"
              v-validate="{ required: true }"
              :state="validateState('posId')"
              aria-describedby="input-posId-live-feedback"
              data-vv-as="posId"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="nameZhHk" class="text-brown"
              >產品名稱 <span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="nameZhHk"
              class="rounded-0"
              name="nameZhHk"
              v-model="formControl.nameZhHk"
              v-validate="{ required: true }"
              :state="validateState('nameZhHk')"
              aria-describedby="input-nameZhHk-live-feedback"
              data-vv-as="nameZhHk"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="inputPassword" class="text-brown"
              >产品名称(简体版本)</label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="nameZhCn"
              class="rounded-0"
              name="nameZhCn"
              v-model="formControl.nameZhCn"
              v-validate="{ required: false }"
              :state="validateState('nameZhCn')"
              aria-describedby="input-nameZhCn-live-feedback"
              data-vv-as="nameZhCn"
            ></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="seriesId" class="text-brown"
              >產品系列 <span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-select
              id="seriesId"
              class="rounded-0"
              name="seriesId"
              v-model="formControl.seriesId"
              v-validate="{ required: true }"
              :options="categories"
              value-field="id"
              text-field="nameZhHk"
              :state="validateState('seriesId')"
              aria-describedby="input-seriesId-live-feedback"
              data-vv-as="seriesId"
            ></b-form-select>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="price" class="text-brown"
              >價格 <span class="text-danger">*</span></label
            >
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="price"
              class="rounded-0"
              name="price"
              v-model="formControl.price"
              v-validate="'required|min_value:0'"
              :state="validateState('price')"
              aria-describedby="input-price-live-feedback"
              data-vv-as="price"
              type="number"
              step="0.01"
            ></b-form-input>
            <b-form-invalid-feedback id="input-price-live-feedback">
              {{ veeErrors.first("price") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="posId" class="text-brown">免運費</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="formControl.isFreeShipping"
              :checked="formControl.isFreeShipping"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="posId" class="text-brown">發送通知</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-checkbox
              name="check-button"
              class="custom-switch--success"
              switch
              v-model="formControl.sendNotification"
              :checked="formControl.sendNotification"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="mb-3">
          <b-col sm="4">
            <label for="discountPercentage" class="text-brown">折扣 (%)</label>
          </b-col>
          <b-col sm="8" lg="6">
            <b-form-input
              id="discountPercentage"
              class="rounded-0"
              name="discountPercentage"
              type="number"
              v-model="formControl.discountPercentage"
              data-vv-as="discountPercentage"
              aria-describedby="input-discountPercentage-live-feedback"
              v-validate="'min_value:0|max_value:100'"
              :state="validateState('discountPercentage')"
              step="0.01"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col sm="4">
            <label for="file-upload" class="text-brown">尺寸指南</label>
          </b-col>
          <b-col sm="8" lg="6">
            <div style="height: 240px">
              <div
                class="p-3 text-brown d-flex flex-column border-brown--light w-full rounded-6px border bg-white"
                v-if="!!pdfSource"
              >
                <div
                  @click="onRemoveSizeGuide()"
                  class="align-self-end justify-content-end"
                  style="margin-bottom: 10px; cursor: pointer"
                >
                  <IconRemoved />
                </div>
                <div
                  v-if="!!pdfSource"
                  v-b-modal.upload-size-guide-modal
                  style="height: 240px; overflow-y: scroll"
                  class="mb-2"
                >
                  <VuePdfEmbed :source="pdfSource" />
                </div>
              </div>
              <div v-else style="height: 240px">
                <div
                  class="mb-3 d-flex flex-column w-full"
                  style="width: 100%; height: 100%"
                >
                  <label
                    v-b-modal.upload-size-guide-modal
                    class="p-3 file-upload-card d-flex flex-column w-full justify-content-center align-items-center text-brown border-brown--light w-full rounded-6px border bg-white"
                  >
                    <template v-if="!sizeGuidePreview">
                      <b-icon
                        style="width: 40px; height: 40px"
                        class="mb-4"
                        icon="image"
                        aria-hidden="true"
                      ></b-icon>
                      <p class="text-muted">上傳圖片</p>
                      <p class="text-muted">只接受 jpg,jpeg,png,pdf格式</p>
                    </template>
                    <template v-else>
                      <div
                        @click.stop="onRemoveSizeGuide()"
                        class="align-self-end justify-content-end"
                        style="margin-bottom: 10px; cursor: pointer"
                      >
                        <IconRemoved />
                      </div>
                      <div
                        class="sizeGuidePreview rounded-6px mb-2"
                        :style="{
                          'background-image': `url(${sizeGuidePreview})`,
                        }"
                      ></div>
                    </template>
                  </label>
                </div>
              </div>
            </div>

            <b-modal
              id="upload-size-guide-modal"
              centered
              size="xl"
              title="上傳尺寸指南"
              hide-footer
            >
              <b-tabs content-class="mt-3">
                <b-tab title="上傳文件" active>
                  <div class="h-50Vh">
                    <b-form-file
                      id="sizeGuidePreview"
                      class="d-none"
                      ref="sizeGuide"
                      name="sizeGuide"
                      v-validate="{
                        required: false,
                        ext: 'jpeg|jpg|png|pdf',
                        size: 15360,
                      }"
                      accept=".jpg, .jpeg, .png, .pdf"
                      :state="validateState('sizeGuide')"
                      aria-describedby="input-sizeGuide-live-feedback"
                      data-vv-as="sizeGuide"
                      @change="onFileChange"
                    ></b-form-file>
                    <b-form-invalid-feedback id="input-sizeGuide-live-feedback">
                      {{ veeErrors.first("sizeGuide") }}
                    </b-form-invalid-feedback>
                    <div
                      class="mb-3 d-flex flex-column w-full justify-content-center align-items-center"
                      style="width: 100%; height: 100%"
                    >
                      <label
                        for="sizeGuidePreview"
                        class="file-upload-card d-flex flex-column w-full justify-content-center align-items-center text-brown border-brown--light w-full rounded-6px mt-2 border bg-white py-2 px-4"
                      >
                        <b-icon
                          style="width: 100px; height: 100px"
                          class="mb-4"
                          icon="image"
                          aria-hidden="true"
                        ></b-icon>
                        <p class="text-muted">上傳圖片</p>
                        <p class="text-muted">只接受 jpg,jpeg,png,pdf格式</p>
                        <p></p>
                      </label>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Media Library">
                  <div
                    class="h-50Vh"
                    style="
                      max-height: 480px;
                      overflow-y: scroll;
                      overflow-x: hidden;
                      padding: 16px 0;
                    "
                  >
                    <b-row>
                      <b-col
                        cols="6"
                        xl="2"
                        md="3"
                        v-for="(imageUrl, idx) in this.sizeGuideImages"
                        class="m-2"
                        :key="idx"
                      >
                        <img
                          v-if="!imageUrl.includes('.pdf')"
                          @click="handleSelectImageClick(idx)"
                          @dblclick="handleDoubleSelectImageClick(imageUrl)"
                          style="cursor: pointer"
                          :src="imageUrl"
                          :class="[
                            selectedIdxGuide === idx
                              ? 'border-2-primary'
                              : 'border',
                          ]"
                          width="160px"
                          height="160px"
                        />
                        <img
                          v-else
                          @click="handleSelectImageClick(idx)"
                          @dblclick="handleDoubleSelectImageClick(imageUrl)"
                          style="cursor: pointer"
                          src="../../assets/pdf.png"
                          :class="[
                            selectedIdxGuide === idx ? 'border-2-primary' : '',
                          ]"
                          width="160px"
                          height="160px"
                        />
                      </b-col>
                    </b-row>
                  </div>
                  <b-button block @click="handleConfirmSelectedImage"
                    >選擇</b-button
                  >
                </b-tab>
              </b-tabs>
            </b-modal>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col sm="4">
            <label for="price" class="text-brown">選項</label>
          </b-col>
          <b-col sm="6">
            <b-card class="mb-2 w-full">
              <div
                class="mt-2"
                v-for="(option, index) in optionTypes[0].optionChoices"
                :key="index"
              >
                <label :for="`newOptionValue-${index}`">選項名稱</label>
                <div class="d-flex justify-content-between">
                  <b-form-input
                    :id="`newOptionValue-${index}`"
                    class="rounded-0 mr-2"
                    :name="`newOptionValue-${index}`"
                    v-model="option.value"
                    v-validate="{ required: false }"
                    :state="validateState(`newOptionValue-${index}`)"
                    :aria-describedby="`input-newOptionValue-${index}-live-feedback`"
                    :data-vv-as="`newOptionValue-${index}`"
                  ></b-form-input>
                  <div
                    style="cursor: pointer"
                    @click="removedOptionChoice(index)"
                  >
                    <IconRemovedMinus />
                  </div>
                </div>
              </div>
              <div class="mt-2">
                <label :for="`optionChoiceValue`">選項名稱</label>
                <div @click="addOptionChoice()" style="cursor: pointer">
                  <IconSmallAdd />
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col sm="4">
            <label for="discountPercentage" class="text-brown">付款方式</label>
          </b-col>
          <b-col sm="8" lg="4" xxl="3">
            <div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">Faster Payment System (FPS)</div>
                <b-form-checkbox
                  name="check-button"
                  class="custom-switch--success"
                  switch
                  v-model="formControl.isFpsAllowed"
                  :checked="formControl.isFpsAllowed"
                ></b-form-checkbox>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="mr-2">Card</div>
                <b-form-checkbox
                  name="check-button"
                  class="custom-switch--success"
                  switch
                  v-model="formControl.isCardAllowed"
                  :checked="formControl.isCardAllowed"
                ></b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-tabs class="tabs--form mb-4">
          <b-tab>
            <template #title>
              <div class="nav-item--text">
                產品描述<span class="text-danger ml-2">*</span>
              </div>
            </template>
            <vue-editor
              id="descriptionZhHk"
              :editorToolbar="descriptionToolbar"
              name="descriptionZhHk"
              v-model="formControl.descriptionZhHk"
              v-validate="{ required: true, max: 2000 }"
              data-vv-as="descriptionZhHk"
              aria-describedby="input-descriptionZhHk-live-feedback"
              placeholder="這款閃閃發光的光環鑲托飾有美麗的扇形密釘鑽石，環繞著中央寶石並裝飾著戒環。鑲有鑽石的畫廊使這枚戒指真正壯觀。"
            />
            <small
              class="d-block text-danger"
              id="input-descriptionZhHk-live-feedback"
              v-if="veeErrors.has('descriptionZhHk')"
              >{{ veeErrors.first("descriptionZhHk") }}</small
            >
          </b-tab>

          <b-tab>
            <template #title>
              <div class="nav-item--text">产品描述 (简体版本)</div>
            </template>
            <vue-editor
              id="descriptionZhCn"
              :editorToolbar="descriptionToolbar"
              name="descriptionZhCn"
              v-model="formControl.descriptionZhCn"
              v-validate="{ required: false, max: 2000 }"
              :state="validateState('descriptionZhCn')"
              data-vv-as="descriptionZhCn"
              placeholder="這款閃閃發光的光環鑲托飾有美麗的扇形密釘鑽石，環繞著中央寶石並裝飾著戒環。鑲有鑽石的畫廊使這枚戒指真正壯觀。"
            ></vue-editor>
            <small
              class="d-block text-danger"
              id="input-descriptionZhCn-live-feedback"
              v-if="veeErrors.has('descriptionZhCn')"
              >{{ veeErrors.first("descriptionZhCn") }}</small
            >
          </b-tab>
        </b-tabs>

        <b-button
          :disabled="!hasVeeErrors || isSubmitting"
          type="submit"
          variant="primary"
          class="rounded-10px float-right px-3 px-lg-5"
          ><b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
          >儲存</b-button
        >
      </b-form>
    </div>
  </div>
</template>

<script>
import { getAllProductSeries } from '@/services/category.service'
import { createProduct, getSizeGuideHistory } from '@/services/product.service'
import { VueEditor } from 'vue2-editor'
import IconRemovedMinus from '../../components/icons/IconRemovedMinus.vue'
import IconSmallAdd from '../../components/icons/IconSmallAdd.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import IconRemoved from '../../components/icons/IconRemoved.vue'

export default {
  name: 'ProductCreate',

  components: {
    VueEditor,
    IconRemovedMinus,
    IconSmallAdd,
    VuePdfEmbed,
    IconRemoved
  },

  data: () => ({
    sizeGuidePreview: null,
    previewImage: null,
    previewImagesList: [],
    descriptionToolbar: [
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ indent: '-1' }, { indent: '+1' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      ['link', 'image']
    ],
    categoryFilters: {
      name: '',
      start: '',
      end: ''
    },
    categories: [],
    formControl: {
      active: true,
      image: [],
      posId: '',
      seriesId: null,
      nameZhHk: '',
      nameZhCn: '',
      descriptionZhHk: '',
      descriptionZhCn: '',
      price: null,
      discountPercentage: 0,
      sizeGuide: null,
      optionCreateDTOList: '',
      isCardAllowed: true,
      isFpsAllowed: true,
      existingSizeGuideUrl: null,
      isFreeShipping: false,
      sendNotification: false
    },
    currentIndex: 0,
    sizeGuideImages: [],
    selectedIdxGuide: -1,
    optionTypes: [
      {
        optionChoices: [],
        optionType: 'option type'
      }
    ],
    pdfSource: null,
    isSubmitting: false
  }),

  mounted () {
    this.getAllSizeGuideHistory()
    getAllProductSeries().then((res) => {
      this.categories = res.data.data.content
    })
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  methods: {
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    async getAllSizeGuideHistory () {
      this.sizeGuideImages = await getSizeGuideHistory()
    },
    onSubmitCreateProduct () {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          return
        }
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('active', this.formControl.active)
        if (
          this.formControl.image != null &&
          this.formControl.image !== undefined &&
          typeof this.formControl.image !== 'string'
        ) {
          const files = this.formControl.image
          for (let i = 0; i < files.length; i++) {
            formData.append('image', files[i])
          }
        }
        formData.append('price', this.formControl.price)
        if (this.formControl.discountPercentage) {
          formData.append(
            'discountPercentage',
            this.formControl.discountPercentage
          )
        } else {
          formData.append('discountPercentage', 0)
        }
        formData.append('posId', this.formControl.posId)
        formData.append('nameZhHk', this.formControl.nameZhHk)
        formData.append('nameZhCn', this.formControl.nameZhCn)
        formData.append('seriesId', this.formControl.seriesId)
        formData.append('descriptionZhHk', this.formControl.descriptionZhHk)
        formData.append('descriptionZhCn', this.formControl.descriptionZhCn)
        formData.append('isCardAllowed', this.formControl.isCardAllowed)
        formData.append('isFpsAllowed', this.formControl.isFpsAllowed)
        formData.append('isFreeShipping', this.formControl.isFreeShipping)
        formData.append('sendNotification', this.formControl.sendNotification)
        if (!(this.formControl.existingSizeGuideUrl || this.formControl.sizeGuide)) {
          formData.append('existingSizeGuideUrl', '')
          formData.append('sizeGuide', new Blob())
        } else if (this.formControl.existingSizeGuideUrl) {
          formData.append('existingSizeGuideUrl', this.formControl.existingSizeGuideUrl)
        } else {
          formData.append('sizeGuide', this.formControl.sizeGuide)
        }
        if (this.optionTypes.length) {
          const obj = this.optionTypes.map((item) => ({
            ...item,
            optionChoices: item.optionChoices.filter((el) => el.value.trim()).map((el) => el.value)
          }))
          formData.append('optionCreateDTOList', JSON.stringify(obj))
        }
        await createProduct(formData).then(
          (res) => {
            if (res.data.success) {
              this.makeToast('success', '產品推廣', '儲存成功')
              setTimeout(() => {
                this.$router.push('/product')
              }, 1500)
            }
          },
          (err) => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
        this.isSubmitting = false
      })
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    browseImage () {
      const files = this.$refs.fileImage.files
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const maxLength = this.formControl.image.length + 1
          if (maxLength > 10) return
          const file = files[i]
          const maxSize = 1024 * 1024
          if (file.size <= maxSize) {
            this.formControl.image.push(file)
            const reader = new FileReader()
            reader.onload = (e) => {
              this.previewImagesList.push(e.target.result)
            }
            reader.readAsDataURL(file)
          }
        }
      }
    },
    removeImage (idx) {
      this.formControl.image.splice(idx, 1)
      this.previewImagesList.splice(idx, 1)
    },
    onFileChange (event) {
      const file = event.target.files[0]
      const maxSize = 15 * 1024 * 1024
      if (file.size <= maxSize) {
        this.formControl.sizeGuide = file
        this.formControl.existingSizeGuideUrl = null
        if (file.type === 'application/pdf') {
          this.pdfSource = URL.createObjectURL(file)
          this.sizeGuidePreview = null
        } else {
          this.sizeGuidePreview = URL.createObjectURL(this.formControl.sizeGuide)
          this.pdfSource = null
        }
        setTimeout(() => {
          this.$bvModal.hide('upload-size-guide-modal')
        }, 150)
      }
    },
    addOptionChoice () {
      this.optionTypes[0].optionChoices.push({ value: '' })
    },
    removedOptionChoice (idx) {
      this.optionTypes[0].optionChoices.splice(idx, 1)
    },

    async handleDoubleSelectImageClick (url) {
      this.formControl.sizeGuide = null
      this.formControl.existingSizeGuideUrl = url
      if (url.includes('.pdf')) {
        this.pdfSource = url
        this.sizeGuidePreview = null
      } else {
        this.sizeGuidePreview = url
        this.pdfSource = null
      }
      setTimeout(() => {
        this.$bvModal.hide('upload-size-guide-modal')
      }, 150)
      this.selectedIdxGuide = -1
    },
    handleSelectImageClick (idx) {
      this.selectedIdxGuide = idx
    },
    handleConfirmSelectedImage () {
      if (this.selectedIdxGuide < 0) return
      const url = this.sizeGuideImages[this.selectedIdxGuide]
      this.handleDoubleSelectImageClick(url)
    },
    updateIndex (newIndex) {
      this.currentIndex = newIndex
    },
    onRemoveSizeGuide () {
      this.sizeGuidePreview = null
      this.pdfSource = null
      this.formControl.sizeGuide = null
      this.formControl.existingSizeGuideUrl = null
    }
  }
}
</script>

<style scoped lang="scss">
.preview-image {
  width: 218px;
  height: 160px;
  background-size: cover;
  background-position: center center;
}
.img-fluid {
  width: 218px;
  height: 160px;
  max-height: 160px !important;
}
.sizeGuidePreview {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.options-card .card-body {
  align-items: center;
  display: flex;
  justify-content: center;
}
.file-upload-card {
  border-width: 2px !important;
  border-style: dashed !important;
  border-radius: 0.5rem !important;
  width: 100%;
  height: 100%;
}
</style>
